//
// forms.scss
//

// Form-control light

.form-control-light {
    background-color: var(--#{$prefix}tertiary-bg) !important;
    border-color: var(--#{$prefix}border-color) !important;
    border-width: $card-border-width;
    border-style: solid;
    border-color: $card-border-color;
}

// Form elements (Color and Range)

input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}

// Custom select

.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

select.form-control {
    &:not([size]):not([multiple]) {
        height: $input-height;
    }
}

select.form-control-sm {
    &:not([size]):not([multiple]) {
        height: $input-height-sm;
    }
}

// Show/Hide Password
.password-eye {
    cursor: pointer;

    &:before {
        font-family: "remixicon";
        content: "\ECB5";
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 16px;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: "\ECB7";
        }
    }
}

.rs-picker-toggle {
    z-index: 0 !important;
}