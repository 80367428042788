//
// faq.scss
//

.faq-question-q-box {
    height: 30px;
    width: 30px;
    color: var(--#{$prefix}primary);
    background-color: rgba(var(--#{$prefix}primary-rgb), 0.25);
    box-shadow: $box-shadow-lg;
    text-align: center;
    border-radius: 50%;
    float: left;
    font-weight: $font-weight-bold;
    line-height: 30px;
}

.faq-question {
    margin-top: 0;
    margin-left: 50px;
    font-weight: $font-weight-semibold;
    font-size: 16px;
    color: var(--#{$prefix}tertiary-color);
}

.faq-answer {
    margin-left: 50px;
}
