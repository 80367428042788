//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
    +.breadcrumb-item {
        &::before {
            font-family: "remixicon";
            font-size: 16px;
            line-height: 1.3;
        }
    }
}